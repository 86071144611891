import React from 'react';
import styled, { keyframes } from 'styled-components';

export default () => (
  <Footer>
    <div>
      <SmallPrint>
        <a href="/privacy-policy/" target="_blank">
          Privacy Policy
        </a>
        <a href="/cookie-policy/" target="_blank">
          Cookie Policy
        </a>
        <button tabIndex={0} className="cky-banner-element">
          Cookie Preferences
        </button>
        <p>
          Dynamic Events is a trading name of Mobile Event Management Limited and is Registered in
          Ireland.
          <br /> © {new Date().getFullYear()} Copyright Dynamic Events Ltd. All rights reserved.
          <br />
          Website designed & developed by{' '}
          <a href="https://www.agencyx.ie" target="_blank" style={{ marginRight: 'initial' }}>
            Agency X.
          </a>
        </p>
      </SmallPrint>
    </div>
  </Footer>
);

const fadeInFooter = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const Footer = styled.footer`
  animation: ${fadeInFooter} 1000ms ease-in-out forwards;
  background-color: var(--dynamic-dark-grey);
  display: flex;
  justify-content: center;

  p,
  a,
  button {
    color: #fff !important;
    font-size: 0.85rem !important;
    font-family: Roboto, sans-serif !important;
    letter-spacing: 0.01em !important;
    font-weight: 300 !important;
  }

  a {
    margin-right: 1.375em;
  }

  button {
    background-color: transparent !important;
    border: none !important;
    cursor: pointer !important;
    text-decoration: underline !important;
    padding: 0 !important;

    &:link,
    &:visited,
    &:hover,
    &:active,
    &:focus {
      background-color: transparent !important;
    }
  }

  > div {
    display: flex;
    margin: 1.15em;
  }
`;

const SmallPrint = styled.div`
  max-width: 400px;
  width: 100%;
  text-align: center;

  p {
    margin-top: 2%;
    line-height: 1.45em;

    @media screen and (min-width: 840px) {
      margin-top: 1.25em;
    }
  }
`;
