import React, { PureComponent, useLayoutEffect } from 'react';
import sizes from 'react-sizes';
import styled from 'styled-components';
import { InView } from 'react-intersection-observer';
import { scrollToAnchor } from 'utils';

function CookiePolicyText ({ handleHeader }) {
  const handleScrollEvent = (userHasScrolled) => handleHeader(userHasScrolled);

  useLayoutEffect(() => {
    const intervalID = window.setInterval(() => {
      if (window.OneTrust) {
        window.OneTrust.initializeCookiePolicyHtml();
        window.clearInterval(intervalID);
      }
    }, 500);
  }, []);

  return (
    <InView onChange={handleScrollEvent} threshold={1}>
      <Wrapper>
        <h1>Dynamic Events Cookie Policy</h1>
        <p style={{ textAlign: 'center' }}>Last updated: March 11th, 2024</p>
        <br />
        <InnerWrapper>
          <b>Introduction</b>
          <p>
            Dynamic Events (“us”, “we”, or “our”) uses cookies on the
            https://www.dynamicevents.ie/ website (the “Service”). By using the Service, you
            consent to the use of cookies. Our Cookies Policy explains what cookies are, how we
            use cookies, how third-parties we may partner with may use cookies on the Service,
            your choices regarding cookies and further information about cookies.
          </p>
          <LinksList>
            <li>
              <a tabIndex={0} onClick={() => scrollToAnchor('1', { offset: -103 })}>
                What are cookies
              </a>
            </li>
            <li>
              <a tabIndex={0} onClick={() => scrollToAnchor('2', { offset: -103 })}>
                How Dynamic Events uses cookies
              </a>
            </li>
            <li>
              <a tabIndex={0} onClick={() => scrollToAnchor('3', { offset: -103 })}>
                Third-party cookies
              </a>
            </li>
            <li>
              <a tabIndex={0} onClick={() => scrollToAnchor('4', { offset: -103 })}>
                What are your choices regarding cookies
              </a>
            </li>
            <li>
              <a tabIndex={0} onClick={() => scrollToAnchor('5', { offset: -103 })}>
                Where can you find more information about cookies
              </a>
            </li>
            <li>
              <a tabIndex={0} onClick={() => scrollToAnchor('6', { offset: -103 })}>
                The types of cookies we use
              </a>
            </li>
          </LinksList>
          <DetailedList>
            <li>
              <div>
                <h4 id="1">What are cookies</h4>
                <p>
                  Cookies are small pieces of text sent to your web browser by a website you
                  visit. A cookie file is stored in your web browser and allows the Service or a
                  third-party to recognize you and make your next visit easier and the Service
                  more useful to you.
                </p>
                <p>
                  Cookies can be “persistent” or “session” cookies. Persistent cookies remain on
                  your personal computer or mobile device when you go offline, while session
                  cookies are deleted as soon as you close your web browser.
                </p>
              </div>
            </li>
            <li>
              <div>
                <h4 id="2">How Dynamic Events uses cookies</h4>
                <p>
                  When you use and access the Service, we may place a number of cookies files in
                  your web browser.
                </p>
                <p>We use cookies for the following purposes:</p>
                <ul style={{ paddingInlineStart: '1em' }}>
                  <li>To enable certain functions of the Service</li>
                  <li>To provide analytics</li>
                </ul>
                <br />
                <p>
                  Please see section 6 - 'The Types Of Cookies We Use' - for a detailed list of
                  cookies used by our website.
                </p>
              </div>
            </li>
            <li>
              <div>
                <h4 id="3">Third-party cookies</h4>
                <p>
                  In addition to our own cookies, we may also use various third-parties cookies to
                  report usage statistics of the Service, deliver advertisements on and through
                  the Service, and so on.
                </p>
              </div>
            </li>
            <li>
              <div>
                <h4 id="4">What are your choices regarding cookies</h4>
                <p>
                  If you’d like to delete cookies or instruct your web browser to delete or refuse
                  cookies, please visit the help pages of your web browser.
                </p>
                <p>
                  Please note, however, that if you delete cookies or refuse to accept them, you
                  might not be able to use all of the features we offer, you may not be able to
                  store your preferences, and some of our pages might not display properly.
                </p>
                <ul style={{ paddingInlineStart: '1em' }}>
                  <li>
                    For the Chrome web browser, please visit this page from Google:&nbsp;
                    <a href="https://support.google.com/accounts/answer/32050" target="_blank">
                      https://support.google.com/accounts/answer/32050
                    </a>
                  </li>
                  <li>
                    For the Internet Explorer web browser, please visit this page from Microsoft:&nbsp;
                    <a href="http://support.microsoft.com/kb/278835" target="_blank">
                      http://support.microsoft.com/kb/278835
                    </a>
                  </li>
                  <li>
                    For the Firefox web browser, please visit this page from Mozilla:&nbsp;
                    <a
                      href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored"
                      target="_blank">
                      https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored
                    </a>
                  </li>
                  <li>
                    For the Safari web browser, please visit this page from Apple:&nbsp;
                    <a href="https://support.apple.com/kb/PH21411?locale=en_US" target="_blank">
                      https://support.apple.com/kb/PH21411?locale=en_US
                    </a>
                  </li>
                  <li>
                    For any other web browser, please visit your web browser's official web pages.
                  </li>
                </ul>
              </div>
            </li>
            <br />
            <li>
              <div>
                <h4 id="5">Where can you find more information about cookies</h4>
                <p>You can learn more about cookies and the following third-party websites:</p>
                <ul style={{ paddingInlineStart: '1em' }}>
                  <li>
                    <a href="http://www.allaboutcookies.org/" target="_blank">
                      All About Cookies:
                    </a>
                  </li>
                  <li>
                    <a href="http://www.networkadvertising.org/" target="_blank">
                      Network Advertising Initiative
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <br />
            <li>
              <div>
                <h4 id="6">The types of cookies we use:</h4>
                <div class= "cky-audit-table-element" />
              </div>
            </li>
          </DetailedList>
        </InnerWrapper>
      </Wrapper>
    </InView>
  );
}

const Wrapper = styled.div`
  padding: 6em 2em 0;

  @media screen and (min-width: 360px) {
    padding: 7em 2em 0;
  }

  @media screen and (min-width: 375px) {
    padding: 8em 2em 0;
  }

  @media screen and (min-width: 967px) {
    padding: 9.25em 2em 0;
  }

  a {
    color: rgb(74, 79, 84);
    outline: none;
  }

  p {
    margin-bottom: 1em;
  }

  h1 {
    text-align: center;
    margin: 0 auto 1em;
  }

  li {
    font-size: 1rem;
    margin-left: 1em;
  }

  ol ul {
    margin-left: 2em;
    list-style-type: disc;
  }

  table {
    margin: 1.25em auto;
  }

  th {
    font-weight: normal;
  }

  td,
  th {
    border: 1px solid black;
    padding: 20px;
  }
`;

const InnerWrapper = styled.div`
  max-width: 850px;
  margin: 0 auto;
`;

const LinksList = styled.ol`
  margin-bottom: 1.5em;
  padding-inline-start: 1.5em;

  a {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const DetailedList = styled.ol`
  padding-left: 0;

  div {
    margin-left: 0.75em;
  }

  h4 {
    margin-bottom: 0.5em;
  }
`;

export default sizes(({ width }) => ({ viewportWidth: width && width }))(CookiePolicyText);
